import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/reset.css';
import './assets/styles/zapweb-new.css';
import './assets/styles/timeline.css';
import './assets/styles/project.css';
import './assets/styles/landing-page.css';
import './assets/styles/about.css';
import './assets/styles/footer.css';
import './assets/styles/navbar.css';
import App from './components/App';

ReactDOM.render(<App />, document.querySelector('#root'));
