import React from 'react';

/**
 * TODO - Modularize card
 * **/

// https://www.w3schools.com/HOWTO/howto_css_timeline.asp
const TimelineCard = ({ card, cardClass }) => {
  function renderBulletList() {
    if (card.desc.length > 0) {
      return card.desc.map((bullet, i) => {
        return (
          <li key={card.date + i}>
            <p className={'experience-bullet'}> {bullet}</p>
          </li>
        );
      });
    } else {
      return <h3> Couldn't load bullet! </h3>;
    }
  }

  return (
    <div>
      <div className={cardClass}>
        <div className="timeline-card-info">
          <h4>{card.title}</h4>
          <h5>{card.company}</h5>
        </div>
        <ul>{renderBulletList()}</ul>
      </div>
    </div>
  );
};

export default TimelineCard;
