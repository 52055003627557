import React from 'react';
import topWave from '../assets/images/top-wave.svg';
import mugPic from '../assets/images/mugpic.jpg';
import { useMediaQuery } from 'react-responsive';

const DescriptionList = () => {
  return (
    <ul id={'about-description-list'}>
      <li>Feel free to call me Zap or just Z.</li>
      <li>My adventure with computers started when I was six. </li>
      <li>I got passionate about coding eight years ago.</li>
      <li>I have been specializing in web development for over two years.</li>
      <li>
        My favorite stack is MERN; however, I can easily adapt to different technologies.
      </li>
      <li>
        Pair programming is kind of my jam and I have spent over two thousand hours doing
        it, so I wish to believe I make for a good coding buddy.
      </li>
    </ul>
  );
};

const AboutInner = () => {
  const tabletView = useMediaQuery({ query: '(max-width: 900px)' });
  const mobileView = useMediaQuery({ query: '(max-width: 600px)' });

  if (mobileView) {
    return (
      <React.Fragment>
        <div id={'about-pic-frame'}>
          <img alt={'mug-pic'} src={mugPic} />
        </div>
        <div id={'about-inner-box'}>
          <div id="about-greeting">Hey there, nice to e-meet you !</div>
          <DescriptionList />
        </div>
      </React.Fragment>
    );
  }

  if (tabletView) {
    return (
      <React.Fragment>
        <div id={'about-inner-box'}>
          <div id={'about-pic-frame'}>
            <img alt={'mug-pic'} src={mugPic} />
          </div>
          <div id="about-greeting">
            Hey there, <div>nice to e-meet you!</div>
          </div>
        </div>
        <DescriptionList />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div id="about-greeting">Hey there, nice to e-meet you!</div>
      <div id={'about-inner-box'}>
        <div id={'about-pic-frame'}>
          <img alt={'mug-pic'} src={mugPic} />
        </div>
        <DescriptionList />
      </div>
    </React.Fragment>
  );
};

const About = () => {
  return (
    <React.Fragment>
      <div id={'nav-bar-start-animation'} style={{ top: '8rem', position: 'absolute' }} />
      <img alt="top-wave" src={topWave} id="top-wave" />
      <div id="about-container">
        <div id={'about-inner-container'}>
          <AboutInner />
        </div>
      </div>
    </React.Fragment>
  );
};

export default About;
