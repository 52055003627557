import React, { Component } from 'react';
import TimelineCard from './TimelineCard';
import { experienceContent } from '../../content/mainContent';
import TimelineSVG from './TimelineSVG';

/**
 * TODO - abstract date and sort by date!!! add a date end key to make your life easier
 * **/

function dateSort(a, b) {
  // split string on space " " and then
  let arrA = a.date.split(' ');
  let arrB = b.date.split(' ');
  if (arrA[0] === arrB[0]) {
    if (arrA[1] === arrB[1]) {
      return arrB[2] - arrA[2];
    }
    return arrB[1] - arrA[1];
  }
  return arrB[0] - arrA[0];
}

// https://www.w3schools.com/HOWTO/howto_css_timeline.asp
class Timeline extends Component {
  renderTimeline() {
    if (experienceContent.length > 0) {
      return experienceContent.sort(dateSort).map((card, i) => {
        if (i % 2 === 0) {
          return (
            <div key={card.date} className="even-split">
              <TimelineSVG svg={card.cardSVG} svgClass={'timeline-svg-left'} />
              <div className={'timeline-date-circle-right'}>
                <div className={'timeline-date-text'}>{card.timeSpent}</div>
              </div>
              <TimelineCard card={card} cardClass={'timeline-card-right'} />
            </div>
          );
        } else {
          return (
            <div key={card.date} className="even-split">
              <TimelineCard card={card} cardClass={'timeline-card-left'} />
              <div className={'timeline-date-circle-left'}>
                <div className={'timeline-date-text'}>{card.timeSpent}</div>
              </div>
              <TimelineSVG svg={card.cardSVG} svgClass={'timeline-svg-right'} />
            </div>
          );
        }
      });
    } else {
      return <h3> Couldn't load info! </h3>;
    }
  }
  render() {
    return (
      <section>
        <div className={'container'}>
          <div id="experience-breaker" className="section-breaker" />
          <h3>Experience</h3>
          <div id="my-timeline">{this.renderTimeline()}</div>
        </div>
      </section>
    );
  }
}

export default Timeline;
