import React from 'react';
import bottomWave from '../assets/images/bottom-wave.svg';

const Footer = () => {
  return (
    <div id="my-footer">
      <img alt="bottom-wave" src={bottomWave} id={'bottom-wave'} />
      <div id={'footer-extra-box'} />
      <div id="my-footer-inner-container">
        <div id="footer-title">Contact Info</div>
        <p className="footer-email-box">
          <i className="fa fa-envelope footer-envelope" />
          zaprin@bubaz.tech
        </p>
        <div className="footer-icons-box">
          <a
            href="https://www.linkedin.com/in/zaprin-ignatiev/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin footer-linkedin" />
          </a>
          <a href="https://github.com/ziroock" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-github" />
          </a>
        </div>
        <p className="footer-copyright-text">&copy; Zaprin Ignatiev 2021</p>
      </div>
    </div>
  );
};

export default Footer;
