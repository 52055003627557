import React from 'react';
import NavBar from './navbar/NavBar';
import About from './About';
import LandingPage from './LandingPage';
import ProjectList from './projects/ProjectList';
import Timeline from './timeline/Timeline';
import Footer from './Footer';

const App = () => {
  return (
    <React.Fragment>
      <NavBar />
      <LandingPage />
      <About />
      <ProjectList />
      <Timeline />
      <Footer />
    </React.Fragment>
  );
};

export default App;
