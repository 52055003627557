$(document).ready(function () {
  let scroll_start = 0;
  let start_change = $('#nav-bar-start-animation');
  let offset = start_change.offset();
  $(document).scroll(function () {
    scroll_start = $(this).scrollTop();
    let navbar = document.getElementById('nav-bar');
    if (scroll_start > offset.top / 2) {
      navbar.style.color = 'white';
      $('#nav-bar').css({ background: 'rgba(10, 10, 10, 0.9)', height: '6rem' });
    } else {
      $('#nav-bar').css({ background: 'rgba(196, 196, 196, 0.2)', height: '8rem' });
      navbar.style.color = 'black';
    }
  });
});
