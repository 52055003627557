import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ProjectPicture, renderDescriptionList, StackList } from './ProjectComponents';

const ProjectRow = ({ project, picPosition, descPosition }) => {
  const noStack = useMediaQuery({ query: '(max-width: 1439px)' });
  const verticalView = useMediaQuery({ query: '(max-width: 940px)' });

  if (verticalView) {
    return (
      <div className={'project-container'}>
        <h4 className={'project-description-title'}>{project.title}</h4>
        <ProjectPicture project={project} position={picPosition} />
        <div className={'project-description-container-' + descPosition}>
          <StackList project={project} />
          {renderDescriptionList(project)}
        </div>
      </div>
    );
  }
  if (noStack) {
    return (
      <div className={'project-container'}>
        <ProjectPicture project={project} position={picPosition} />
        <div className={'project-description-container-' + descPosition}>
          <h4 className={'project-description-title'}>{project.title}</h4>
          <StackList project={project} />
          {renderDescriptionList(project)}
        </div>
      </div>
    );
  }

  return (
    <div className={'project-container'}>
      <ProjectPicture project={project} position={picPosition} />
      <StackList project={project} />
      <div className={'project-description-container-' + descPosition}>
        <h4 className={'project-description-title'}>{project.title}</h4>
        {renderDescriptionList(project)}
      </div>
    </div>
  );
};

export default ProjectRow;
