import React, { Fragment } from 'react';
import { useMediaQuery } from 'react-responsive';
import '../../utils/activateNavBar';
// import '../../utils/currentSection';

const Links = () => {
  return (
    <ul id="nav-bar-link-box">
      <li>
        <a href={'#about-breaker'} className="no-style">
          About
        </a>
      </li>
      <li>
        <a href={'#project-breaker'} className="no-style">
          Projects
        </a>
      </li>
      <li>
        <a href={'#experience-breaker'} className="no-style">
          Experience
        </a>
      </li>
    </ul>
  );
};

const Logo = () => {
  const logoSplit = useMediaQuery({ query: '(max-width: 700px)' });
  const logoMobile = useMediaQuery({ query: '(max-width: 500px)' });

  if (logoMobile) {
    return (
      <div className="nav-bar-logo">
        <span style={{ fontWeight: '600' }}> Zaprin Ignatiev </span>
      </div>
    );
  }

  if (logoSplit) {
    return (
      <div className="nav-bar-logo">
        <div>
          your friendly neighbourhood
          <span style={{ fontWeight: '600' }}> software geek </span>
        </div>
        <div id={'nav-bar-logo-bottom'}>
          <div id={'nav-bar-bottom-logo-text'}>at your service</div>
        </div>
      </div>
    );
  }

  return (
    <div className="nav-bar-logo">
      your friendly neighbourhood
      <span style={{ fontWeight: '600' }}> software geek </span>
      at your service
    </div>
  );
};

const SideNavBar = () => {
  function closeNav() {
    document.getElementById('mySidenav').style.width = '0';
    document.getElementById('nav-bar').style.display = 'flex';
  }
  return (
    <div id="mySidenav" className="sidenav">
      <div id={'side-nav-container'}>
        <p
          className="closebtn"
          onClick={() => {
            closeNav();
          }}
          style={{ cursor: 'pointer' }}
        >
          &times;
        </p>
        <a href="#root" onClick={closeNav} className="no-style">
          <p>Home</p>
        </a>
        <a href="#about-breaker" onClick={closeNav} className="no-style">
          <p>About</p>
        </a>
        <a href="#project-breaker" onClick={closeNav} className="no-style">
          <p>Projects</p>
        </a>
        <a href="#experience-breaker" onClick={closeNav} className="no-style">
          <p>Experience</p>
        </a>
      </div>
      <div id={'side-nav-contact-info'}>
        <p>Contact</p>
        <p>zaprin@bubaz.tech</p>
        <a
          href="https://www.linkedin.com/in/zaprin-ignatiev/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-linkedin sidenav-icon" />
        </a>
      </div>
    </div>
  );
};

const NavBar = () => {
  function openNav() {
    let navbarLen = '250px';
    console.log(window.innerWidth);
    if (window.innerWidth > 1600) {
      navbarLen = '400px';
    }
    document.getElementById('mySidenav').style.width = navbarLen;
    document.getElementById('nav-bar').style.display = 'none';
  }

  return (
    <React.Fragment>
      <div id="nav-bar">
        <div id="nav-bar-content">
          <a href={'#root'} className="no-style">
            <Logo />
          </a>
          {/*<NavBarRight />*/}
          <Links />
          <div id="nav-bar-contact-box">
            <div id={'contact-button'}>
              <p>Contact</p>
            </div>
            <div className="dropdown-content">
              <p>zaprin@bubaz.tech</p>
            </div>
          </div>
          <div
            id={'nav-burger'}
            onClick={() => {
              openNav();
            }}
          >
            <i className="fas fa-bars fa-lg" />
          </div>
        </div>
      </div>
      <SideNavBar />
    </React.Fragment>
  );
};

export default NavBar;

// const NavBarRight = () => {
//     const bringBurger = useMediaQuery({ query: '(max-width: 1300px)' });
//     const contactInBurger = useMediaQuery({ query: '(max-width: 900px)' });
//     if (contactInBurger) {
//         return (
//             <Fragment>
//                 <i className="fas fa-bars fa-lg" />
//             </Fragment>
//         );
//     }
//
//     if (bringBurger) {
//         return (
//             <Fragment>
//                 <div id="nav-bar-contact-box">
//                     <p>Contact</p>
//                 </div>
//                 <i className="fas fa-bars fa-lg" />
//             </Fragment>
//         );
//     }
//     return (
//         <Fragment>
//             <Links />
//             <div id="nav-bar-contact-box">
//                 <p>Contact</p>
//             </div>
//         </Fragment>
//     );
// };
