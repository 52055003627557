import React from 'react';

export const ProjectPicture = ({ project, position }) => {
  return (
    <div className={'project-picture-container-' + position}>
      <div className={'project-image-' + position}>
        <ProjectLinks githubLink={project.githubLink} activeLink={project.activeLink} />
        <img className={'project-image'} alt={'project-image'} src={project.imgSrc} />
      </div>
    </div>
  );
};

// export const ProjectDescription = ({ project, position }) => {
//   return (
//     <div className={'project-description-container'}>
//       <div className={'project-description-container-right'}>
//         <h4 className={'project-description-title'}>{project.title}</h4>
//         {renderDescriptionList(project)}
//       </div>
//     </div>
//   );
// };

const ProjectLinks = ({ githubLink, activeLink }) => {
  let renderGithubLink = () => {
    if (githubLink) {
      return (
        <a
          href={githubLink}
          target="_blank"
          rel="noopener noreferrer"
          className={'project-github-icon project-icon'}
        >
          <i
            className="fab fa-github-square"
            // style={{ fontSize: '5rem', color: 'black' }}
          />
        </a>
      );
    }
    return null;
  };
  let renderActiveLink = () => {
    if (activeLink) {
      return (
        <a
          href={activeLink}
          target="_blank"
          rel="noopener noreferrer"
          className={'project-active-icon project-icon'}
        >
          <i
            className="fas fa-external-link-square-alt"
            // style={{ fontSize: '5rem', color: 'black' }}
          />
        </a>
      );
    }
    return null;
  };
  return (
    <React.Fragment>
      {renderActiveLink()}
      {renderGithubLink()}
    </React.Fragment>
  );
};

export function renderDescriptionList(project) {
  if (project.desc.length > 0) {
    return project.desc.map((desc, i) => {
      return (
        <p className={'project-description-paragraph'} key={project.title + i}>
          &emsp;&ensp;{desc}
        </p>
      );
    });
  } else {
    return <h3> Couldn't load description! </h3>;
  }
}
export const StackList = ({ project }) => {
  console.log(' I AM HERE BAI HUI');
  if (project.stack.length > 0) {
    return (
      <div className={'project-stack-box'}>
        {project.stack.map((bullet) => {
          return (
            <div className="project-stack-entry" key={project.pos + bullet}>
              <div className={'project-stack-entry-text'}>{bullet}</div>
            </div>
          );
        })}
      </div>
    );
  } else {
    return <h3> Couldn't load bullet! </h3>;
  }
};
