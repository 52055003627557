import React from 'react';
import background from '../assets/images/night-city-background.jpg';

const LandingPage = () => {
  return (
    <React.Fragment>
      <div id="landing-page-container">
        <img id="landing-background-img" alt="landing-background" src={background} />
        <div id="title-box">
          <h1 id={'title'}>Zaprin Ignatiev</h1>
          <h2 id={'sub-title'}>FULL STACK DEVELOPER</h2>
        </div>
      </div>
      <div id="about-breaker" className="section-breaker" />
    </React.Fragment>
  );
};

export default LandingPage;
