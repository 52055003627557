import React from 'react';

// https://www.w3schools.com/HOWTO/howto_css_timeline.asp
const TimelineSVG = ({ svg, svgClass }) => {
  return (
    <div className={'timeline-svg-container'}>
      <div className={svgClass}>
        <img className={'timeline-svg'} src={svg} alt={'timeline-svg'} />
      </div>
    </div>
  );
};

// Add default properties refer to textbook

// change the class name of left and right the same as I did in the cards above

export default TimelineSVG;
