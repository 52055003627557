// import projectPic from '../assets/corridorInstallation.jpg';
exports.projectContent = [
  {
    desc:
      'Mango Media is a fun project that I have been developing for a while now. It is a social media prototype that is focused on planning activities with your friends. I love to hike, travel, spend time at the ocean, and outdoor activities with friends. But as responsibilities keep piling up and getting together with the gang for an adventure is getting harder and harder. This is why I decided to do something about it, ease our life, and develop an application that will focus on friends groups and the activities organized within the group. I am not crazy, so I have purposefully delayed public access to the project due to the pandemic. I have attached an older version of the project, hosted for free on Heroku, so pardon the slow initial load up. It takes a second for my project to get to the top of the queue.',
    imgSrc:
      'https://zap-personal-web.s3-us-west-2.amazonaws.com/mango-media-card-cover.png',
    title: 'Mango Media',
    link: 'https://mango-media.herokuapp.com/',
    stack: ['MERN', 'Passport', 'SendGrid', 'Heroku', 'Redux'],
    pos: 1,
  },
  {
    desc:
      'File System controller is an application that automates and optimizes bulk file tasks, allowing the user to browse as well as manipulate the file system with ease. I worked closely with a client to design the perfect UX/UI based on their preferences and needs using Qt. I developed the project with C++ 17 for lower-level access, speed and security. I tested it to ensure 100% edge case coverage and reduced the user permissions appropriately to decrease the risk of mistakes. The project is ready to deploy on both macOS and Ubuntu. The client requested that I packaged the application in an installable for macOS.',
    imgSrc:
      'https://zap-personal-web.s3-us-west-2.amazonaws.com/fs-controller-card-img.png',
    title: 'File System Controller',
    link: 'https://github.com/ziroock/fs-controller',
    stack: ['C++', 'Qt', 'macOS', 'Installer', 'App', 'UX/UI', 'Linux'],
    pos: 3,
  },
  {
    desc:
      'Mass Survey Email is my first web application using the MERN stack. I took a Udemy course, by Stephen Gider a leading face in web development, to improve my understanding of modern web technologies. It gave me a better idea of when and how to use each unique and powerful feature introduced in the videos. The app is architected to send mass emails to a big list of users for the purpose of collecting feedback. It includes the following features: card payments, Google OAuth, and email handling. I developed solid habits and techniques to properly protect credentials and API keys. Using Redux Form, I allow the user to review the content of the survey they create before sending as well as preserving it in case the user wants to go back and edit it.',
    imgSrc:
      'https://zap-personal-web.s3-us-west-2.amazonaws.com/mass-email-card-cover.png',
    title: 'Mass Email Survey App',
    link: 'https://github.com/ziroock/mass-survey-emails',
    stack: ['MERN', 'Passport', 'SendGrid', 'Stripe', 'Heroku', 'Redux'],
    pos: 2,
  },
  {
    desc:
      'I was tasked to code up a table with sortable columns in React running on a small Ruby on Rails server that fetches the data from a database. The client specified that the rendered table should display names, addresses, cities, regions, countries, and DOBs for each person in the database. Each column is sortable and each component is created from scratch.',
    imgSrc:
      'https://zap-personal-web.s3-us-west-2.amazonaws.com/dynamic-react-table-card-img.png',
    title: 'Dynamic React Table',
    link: 'https://github.com/ziroock/dynamic-react-table',
    stack: ['Ruby on Rails', 'PostgreSQL', 'React'],
    pos: 4,
  },
];

exports.experienceContent = [
  {
    title: 'Software Engineer [Research]',
    date: '2020 12 31',
    timeSpent: 'Apr 2020 Present',
    company: 'Rowzzy Co, San Francisco, CA',
    cardSVG:
      'https://zap-personal-web.s3-us-west-2.amazonaws.com/cartoon-san-francisco-skyline-black.svg',
    desc: [
      'Using Javascript to work on the product’s full-stack R&D.',
      'The aim is to create a personalized cross-platform A.I. assistant, which provides hands-free communication.',
      'Worked on the creation and deployment of the project’s CI/CD pipeline. ',
      'Actively testing and researching trending technologies in order to ensure the project’s stack stays up to date. ',
      'Working with frameworks and technologies like; Docker, React, Next.js, Redux, MangoDB, Node.js, GraphQL, Apollo and more...',
    ],
  },
  {
    title: 'Student Ambassador',
    company: 'College of L & S, Davis, CA',
    timeSpent: 'Apr 2018 Jun 2019',
    date: '2018 4 15',
    cardSVG:
      'https://zap-personal-web.s3-us-west-2.amazonaws.com/uc-davis-black-logo.png',
    desc: [
      'Organized both educational and social events, within the College of Letters ' +
        'and Science, such as the annual UC Davis conference for Women in Mathematics and ' +
        'Physical Sciences, attended by over 300 hundred people.',
      'Enhanced the outreach of the College of L & S by assisting student-led initiatives in' +
        ' recruitment, which increased the applicants accepting their offer by 20%.',
    ],
  },
  {
    title: 'Computer Science & Math Tutor',
    company: 'SR Junior College, Santa Rosa, CA',
    timeSpent: 'Sep 2016 Jun 2017',
    date: '2016 6 17',
    cardSVG: 'https://zap-personal-web.s3-us-west-2.amazonaws.com/tutor-svg-black.svg',
    desc: [
      'Tutored STEM subjects in both group and one-on-one settings',
      'Guided students in running, debugging, and bettering their C++ and Java projects',
      'Helped students to pass math courses ranging in difficulty from “Basic Algebra” to “Differential Equations”',
      'Assisted students with completing their labs and homework in the following Physics classes: Classical Mechanics, Optics, Waves, Thermodynamics, Electricity, and Magnetism',
    ],
  },
  {
    title: 'Freelance Software Developer',
    company: 'ENVIRONews, Sebastopol, CA',
    timeSpent: 'Jan 2021 Present',
    date: '2021 1 19',
    cardSVG: 'https://zap-personal-web.s3-us-west-2.amazonaws.com/fs-svg-black.png',
    desc: [
      'Automate and optimize a manual video editing pipeline, which cuts down editing time from 15min to maximum of 5 min, which is an increase of 300% in productivity.',
      'Architected a File System controller for Mac OS and Linux, which automates and optimizes bulk tasks, allowing the user to browse as well as manipulate the file system with ease. ',
      'Worked closely with the client to design the perfect UX/UI based on their preferences and needs using Qt.',
      'Developed the project with C++ 17. It is tested & ready to deploy on both macOS and Ubuntu.',
      'Packaged the application in an installable for macOS',
    ],
  },
];

exports.projectContentNew = [
  {
    desc: [
      'Mango Media is a fun project I have been developing in my free time. It is a social media prototype that will be focused on planning activities with your friends. ',
      'The features it presently supports are friends, search, image upload, user photo gallery, user profile, posts, feed, and email invitations. As well as post edit/delete, photo upload/delete, and allowing the user to change profile and cover photos. ',
      "Currently, I am polishing the User Interface so I apologize if the link doesn't load I am most likely pushing a new version. Also, hosted for free on Heroku, so pardon the slow initial load up. It takes a second for my project to get to the top of the queue.",
    ],
    imgSrc:
      'https://zap-personal-web.s3-us-west-2.amazonaws.com/mango-media-card-cover.png',
    title: 'Mango Media',
    activeLink: 'https://mango-media.herokuapp.com/',
    githubLink: 'https://github.com/ziroock/mango-media',
    stack: ['MERN', 'Passport', 'SendGrid', 'Heroku', 'Redux'],
    pos: 1,
  },
  {
    desc: [
      'File System controller is an application that automates and optimizes bulk file tasks, allowing the user to browse as well as manipulate the file system with ease. I worked closely with a client to design the perfect UX/UI based on their preferences and needs using Qt. ',
      'I developed the project with C++ 17 for lower-level access, speed and security. I tested it to ensure 100% edge case coverage and reduced the user permissions appropriately to decrease the risk of mistakes. ',
      'The project is ready to deploy on both macOS and Ubuntu. The client requested that I packaged the application in an installable for macOS.',
    ],
    imgSrc:
      'https://zap-personal-web.s3-us-west-2.amazonaws.com/fs-controller-card-img.png',
    title: 'File System Controller',
    activeLink: '',
    githubLink: '',
    stack: ['C++', 'Qt', 'macOS', 'Installer', 'UX/UI', 'Linux'],
    pos: 3,
    //'App'
  },
  {
    desc: [
      'Mass Survey Email is my first web application using the MERN stack. I took a Udemy course, by Stephen Gider a leading face in web development, to improve my understanding of modern web technologies. It gave me a better idea of when and how to use each unique and powerful technology introduced in the videos. ',
      'The app is architected to send mass emails to a big list of users for the purpose of collecting feedback. It includes the following features: card payments, Google OAuth, and email handling. Using Redux Form, I allow the user to review the content of the survey they create before sending as well as preserving it in case the user wants to go back and edit it. I also developed solid habits and techniques to properly protect credentials and API keys. ',
    ],
    imgSrc:
      'https://zap-personal-web.s3-us-west-2.amazonaws.com/mass-email-card-cover.png',
    title: 'Mass Email Survey App',
    activeLink: 'https://shielded-mountain-06419.herokuapp.com/',
    githubLink: 'https://github.com/ziroock/mass-survey-emails',
    stack: ['MERN', 'Passport', 'SendGrid', 'Stripe', 'Heroku', 'Redux'],
    pos: 2,
  },
  {
    desc: [
      'I was tasked to code up a table with sortable columns in React. The data was stored in a PostgreSQL DB and served from a small server running on Ruby on Rails.',
      'The client specified that the rendered table should display names, addresses, cities, regions, countries, and DOBs for each person in the database. Each column is sortable and each component is created from scratch.',
    ],
    imgSrc:
      'https://zap-personal-web.s3-us-west-2.amazonaws.com/dynamic-react-table-card-img.png',
    title: 'Dynamic React Table',
    activeLink: '',
    githubLink: 'https://github.com/ziroock/dynamic-react-table',
    stack: ['Rails', 'PostgreSQL', 'React'],
    pos: 4,
  },
];

// module.exports = { projectContent, experienceContent, projectContentNew };
