import React, { Component } from 'react';
import { projectContentNew } from '../../content/mainContent';
import ProjectRow from './ProjectRow';

// https://www.w3schools.com/HOWTO/howto_css_timeline.asp
class ProjectList extends Component {
  renderProjects() {
    if (projectContentNew.length > 0) {
      return projectContentNew
        .sort((a, b) => {
          return a.pos - b.pos;
        })
        .map((project) => {
          let picPos = 'right';
          let descPos = 'left';
          if (project.pos % 2) {
            picPos = 'left';
            descPos = 'right';
          }
          return (
            <ProjectRow
              project={project}
              picPosition={picPos}
              descPosition={descPos}
              key={project.title}
            />
          );
        });
    } else {
      return <h3> Couldn't load info! </h3>;
    }
  }

  render() {
    return (
      <div id={'project-list'}>
        <div id="project-breaker" className="section-breaker" />
        <h3>Projects</h3>
        {this.renderProjects()}
      </div>
    );
  }
}

// TODO:
//      - need to bring in props for rendering left and right
//      - need to set defaultProps
//      - REFER TO TEXTBOOK!!!

export default ProjectList;
